#root,
.__wrapper,
body {
  min-height: 85vh;
  position: relative;
  margin: 0;
  padding-bottom: 85px;
  box-sizing: border-box;
}
@media screen and (max-width: 575px) {
  #root,
  .__wrapper,
  body {
    min-height: 89vh;
    position: relative;
    margin: 0;
    padding-bottom: 103px;
    box-sizing: border-box;
  }
}
footer {
  position: absolute;
  bottom: 0;
}
html {
  overflow-x: hidden;
}
select {
  line-height: 21px !important;
}
input::placeholder {
  overflow: visible;
}
input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #fff;
}
input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #1890ff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #fff;
}
.__antdspin {
  margin: 20px 0;
  text-align: center;
}
.custom-file-control,
.form-control,
.is-focused .custom-file-control,
.is-focused .form-control {
  background-image: linear-gradient(0deg, #1890ff 2px, rgba(0, 150, 136, 0) 0),
    linear-gradient(0deg, rgba(0, 0, 0, 0.26) 1px, transparent 0);
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  padding-top: 5px !important;
  min-width: 18px !important;
  margin-right: 0px !important;
  font-size: 18px !important;
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 4px 2px 0 2px !important;
}
.text-primary {
  color: #1890ff !important;
}
.ant-menu {
  font-size: 13px;
}
p {
  text-align: justify;
}
.ant-card {
  margin-bottom: 5px !important;
}
